import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import MarqueeClients from "../components/MarqueeClients";
import IndustriesCarouselHyper from "../components/IndustriesCarouselHyper";
import Mikan from "mikanjs";
// media
import iconPlan from "../img/icon-plan.svg";
import iconIncrease from "../img/icon-increase.svg";
import iconCrew from "../img/icon-improve-crew.svg";
import iconCapitalize from "../img/icon-capitalize.svg";

export default props => {
  return (
    <Layout page="hypercast">
      <SEO
        title="tomorrow.io™で ビジネスの意思決定"
        description="tomorrow.io weather software is a MicroWeather nowcasting dashboard with live HD weather maps and layers of new weather data sources."
        pathname={props.location.pathname}
        image={`https://www.tomorrow.io${props.data.hero.childImageSharp.fluid.src}`}
      />
      <section className="c-section bg-dark-violet text-white pb-4">
        <div className="container">
          <div className="row mb-6">
            <div className="col-lg-6">
              <h1 className="h-2-sm mb-4">
              tomorrow.io<sup className="c-sup">&trade;</sup>で<br/>
                ビジネスの意思決定
              </h1>
              <h2 className="fw-400 lh-md fs-lg mb-4">
                オールインワンの気象予報ダッシュボードへようこそ。<br/>
                正しい情報を入手、気づきを提供し、行動を導く。
              </h2>
              <a
                className="btn btn-lg btn-primary mb-5"
                href="#more"
                id="hypercast-1"
              >
                仕組みをみる
              </a>
            </div>

            <div className="col-lg-6">
              <Img
                className="shadow rounded-lg"
                fluid={props.data.hero.childImageSharp.fluid}
                alt="Business decisions. Powered by tomorrow.io"
              />
            </div>
          </div>
          <div className="ai ai-delay-4">
            <MarqueeClients light/>
          </div>
        </div>
      </section>

      <section className="c-section bg-dark-violet text-white pb-5" id="more">
        <div className="container">
          <LazyLoad height={500} once>
            <div className="row justify-content-center mb-6 mb-lg-8">
              <div className="col-lg-5">

                <h2 className="h-3 mb-4 fw-400">
                  ひとつの場所に、すべての<br/>正しい情報を集約
                </h2>
                <ul className="c-list mb-4 fs-lg">
                  <li className="c-list-item-circle">
                    世界中をカバーする、分単位の降水量、雷、<br/>大気質のハイパーローカル気象データの活用
                  </li>
                  <li className="c-list-item-circle">
                    過去、リアルタイム、予報で20以上の違う<nobr>気象予報</nobr>パラメーターと地図レイヤ
                  </li>
                  <li className="c-list-item-circle">
                    気象トレンドのカスタマイズ照会
                  </li>
                </ul>
                <a
                  className="btn btn-lg btn-primary mb-4"
                  href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                  id="hypercast-2"
                >
                  さっそくデモを予約
                </a>

              </div>
              <div className="col-lg-7">

                <img
                  className="img-fluid shadow rounded-lg"
                  src="https://cms2.climacell.co/wp-content/uploads/2019/11/hc-map.gif"
                  alt="Get all the right information in&nbsp;one place"
                />

              </div>
            </div>
          </LazyLoad>

          <LazyLoad height={500} once>
            <div className="row justify-content-center mb-6 mb-lg-8">
              <div className="col-lg-5 order-lg-last">

                <h2 className="h-3 mb-4 fw-400">
                  ビジネスに、パーソナライズされた気づきを提供
                </h2>
                <ul className="c-list mb-4 fs-lg">
                  <li className="c-list-item-circle" dangerouslySetInnerHTML={{__html: Mikan('いつ、どこで、どのように、天候がビジネスに及ぼす影響を正確に把握')}}/>
                  <li className="c-list-item-circle" dangerouslySetInnerHTML={{__html: `ハイパー・パーソナライズされた複数ロケー<br/>ションの気象インサイトを重大度レベルで表示`}}/>
                  <li className="c-list-item-circle" dangerouslySetInnerHTML={{__html: Mikan('パーソナライズされたインサイト・ビルダーで、独自のルールと許容範囲を設定するか、予め産業に合うように用意されたプリセット版を利用')}}/>
                </ul>
                <Link
                  className="btn btn-lg btn-primary mb-4"
                  to="/customer-stories/"
                  id="hypercast-3"
                >
                  お客様の体験ストーリーをみる
                </Link>

              </div>
              <div className="col-lg-7">

                <img
                  className="img-fluid shadow rounded-lg"
                  src="https://cms2.climacell.co/wp-content/uploads/2019/11/hc-insights.gif"
                  alt="Generate personalized insights for your business"
                />

              </div>
            </div>
          </LazyLoad>

          <LazyLoad height={500} once>
            <div className="row justify-content-center">
              <div className="col-lg-5">

                <h2 className="h-3 mb-4 fw-400">
                  行動を導き、<nobr>より良い意思決定をする</nobr>
                </h2>
                <ul className="c-list mb-4 fs-lg">
                  <li className="c-list-item-circle" dangerouslySetInnerHTML={{__html: Mikan('特定の地域や天候状況に、カスタマイズされたアラートの設定')}}/>
                  <li className="c-list-item-circle" dangerouslySetInnerHTML={{__html: Mikan('組織中で、情報と取る必要のある行動について共有')}}/>
                </ul>
              </div>
              <div className="col-lg-7">
                <img
                  className="img-fluid shadow rounded-lg"
                  src="https://cms2.climacell.co/wp-content/uploads/2019/11/hc-actions.gif"
                  alt="Drive actions and make better decisions"
                />

              </div>
            </div>
          </LazyLoad>
        </div>
      </section>

      <section className="c-section bg-dark-violet pb-0">
        <div className="container">
          <h2 className="h-2-sm text-center mb-3 mb-lg-5 text-white">
            お客様の声がもっとも信頼のおける評価です
          </h2>
          <LazyLoad height={500} offset={600} once>
            <IndustriesCarouselHyper/>
          </LazyLoad>
        </div>
      </section>

      <section className="c-section bg-dark-violet text-white text-center">
        <div className="container">

          <h2 className="h-2-sm mb-5">
            JetBlueによる利用体験を公開
          </h2>
          <LazyLoad height={400} once>
            <div className="embed-responsive embed-responsive-16by9 col-md-8 mx-auto shadow mb-7">
              <iframe
                className="embed-responsive-item rounded-lg"
                src="https://www.youtube.com/embed/Tm3VwQL1B5c?rel=0"
                frameBorder="0"
                allowFullScreen
                title="JetBlue CEO Robin Hayes Talking About ClimaCell On Washington Post"
              />
            </div>
          </LazyLoad>
          <div className="d-flex flex-wrap justify-content-center justify-content-lg-between fs-lg mb-6">
            <div
              className="my-3 my-lg-0 flex-grow-1"
              style={{ maxWidth: 210 }}
            >
              <img className="mb-3" height={100} src={iconPlan} alt=""/>
              <div className="px-2 px-lg-0">十分に備える</div>
            </div>
            <div
              className="my-3 my-lg-0 flex-grow-1"
              style={{ maxWidth: 210 }}
            >
              <img className="mb-3" height={100} src={iconIncrease} alt=""/>
              <div className="px-2 px-lg-0">
                オペレーション効率を最適化
              </div>
            </div>
            <div
              className="my-3 my-lg-0 flex-grow-1"
              style={{ maxWidth: 210 }}
            >
              <img className="mb-3" height={100} src={iconCrew} alt=""/>
              <div className="px-2 px-lg-0">スタッフの安全性向上</div>
            </div>
            <div
              className="my-3 my-lg-0 flex-grow-1"
              style={{ maxWidth: 210 }}
            >
              <img
                className="mb-3"
                height={100}
                src={iconCapitalize}
                alt=""
              />
              <div className="px-2 px-lg-0">
                ビジネス契機を資産化
              </div>
            </div>
          </div>
          <div className="text-center">
            <h2 className="h-2-sm mb-6">
              ビジネスに最高の気象予報<br/>
              インテリジェンスエンジン。
            </h2>
            <a
              className="btn btn-lg btn-primary ai ai-delay-3"
              href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
              id="hypercast-4"
            >
              さっそくデモを予約
            </a>
          </div>

        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "impact-analysis-construction.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
