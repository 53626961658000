import React, { Component } from "react";
import Malarquee from "react-malarquee";
// logos
import logoJet from "../img/jetblue-logo-color.png";
import logoVia from "../img/via-logo-color.png";
import logoUsta from "../img/usta-logo-big.png";
import logoDelta from "../img/delta-logo-color.png";
import logoPatr from "../img/patriots-logo-color.png";
import logoCastle from "../img/white-castle-logo.png";
import logoUasidekick from "../img/uasidekick-logo.png";
import logoJcaii from "../img/jcaii-logo.png";
import logoProcore from "../img/logo-procore.svg";
import logoAmadeus from "../img/amadeus-logo.png";
import logoCahun from "../img/logo-cajun.png";
import logoOsborn from "../img/logo-osborn.png";
import logoKorell from "../img/logo-korellis.png";
import logoFlynn from "../img/logo-flynn.png";

class MarqueeClients extends Component {
  state = {
    malarqueeRate: 50,
    location: null,
  };

  MalarqueeOnHover = () => {
    this.setState({ malarqueeRate: 0 });
  };

  MalarqueeOnLeave = () => {
    this.setState({ malarqueeRate: 50 });
  };

  componentDidMount() {
    let location = window.location.pathname;
    this.setState({ location });
  }

  render() {
    const { malarqueeRate, location } = this.state;
    return (
      <div className="container">
        <div className="text-center">導入実績</div>

        <Malarquee
          onMouseEnter={this.MalarqueeOnHover}
          onMouseLeave={this.MalarqueeOnLeave}
          rate={malarqueeRate}
        >
          <div className="d-flex justify-content-start align-items-center py-4">
            <img
              className="mr-5 mr-md-7"
              src={logoJet}
              width={80}
              style={this.props.light && { filter: "invert(.3)" }}
              alt=""
            />
            <img className="mr-5 mr-md-7" src={logoVia} width={80} alt="" />
            <img
              className="mr-5 mr-md-7"
              src={logoUsta}
              width={130}
              style={this.props.light && { filter: "invert(1)" }}
              alt=""
            />
            <img
              className="mr-5 mr-md-7"
              src={logoDelta}
              width={100}
              style={this.props.light && { filter: "invert(1)" }}
              alt=""
            />
            <img className="mr-5 mr-md-7" src={logoPatr} width={90} alt="" />
            {location === "/industry/construction/" && (
              <img
                className="mr-5 mr-md-7"
                src={logoCastle}
                width={100}
                alt=""
              />
            )}
            <img className="mr-5 mr-md-7" src={logoJcaii} width={100} alt="" />
            <img
              className="mr-5 mr-md-7"
              src={logoProcore}
              width={100}
              style={this.props.light && { filter: "invert(1)" }}
              alt=""
            />
            <img
              className="mr-5 mr-md-7"
              src={logoAmadeus}
              width={100}
              alt=""
            />
            <img
              className="mr-5 mr-md-7"
              src={logoUasidekick}
              width={80}
              style={this.props.light && { filter: "invert(.4)" }}
              alt=""
            />
            <img className="mr-5 mr-md-7" src={logoFlynn} width={80} alt="" />

            {location === "/industry/construction/" && (
              <img
                className="mr-5 mr-md-7"
                src={logoCahun}
                width={100}
                alt=""
              />
            )}
            {location === "/industry/construction/" && (
              <img
                className="mr-5 mr-md-7"
                src={logoOsborn}
                width={98}
                alt=""
              />
            )}
            <img className="mr-5 mr-md-7" src={logoKorell} width={60} alt="" />
          </div>
        </Malarquee>
      </div>
    );
  }
}

export default MarqueeClients;
